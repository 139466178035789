import { Component, OnInit } from '@angular/core';
import {DataService} from '../../service/data.service';
import {Category} from '../../models/category.model';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  categories: Category[] = [];
  filteredCategories: Category[] = [];

  constructor(private dataService: DataService) { }

  ngOnInit(): void {
    this.getCategories();
  }

  getCategories() {
    this.dataService.getAllCategories().subscribe((data) => {
      this.categories = data;
      this.filteredCategories = data.slice(0, 6);
    });
  }

  get currentYear() {
    return new Date().getFullYear();
  }

}
